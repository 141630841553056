@mixin responsive-size($prop, $value-start, $screen-start, $value-end: 0rem, $screen-end: 0px) {
  @if string.index('px', math.unit($screen-start)) == null {
    @error "Min/max breakpoints must be specified in pixels.";
  }
  @if $value-start == $value-end {
    @include special-prop($prop, $value-start);
  } @else {
    $screen-start: strip-unit($screen-start);
    $screen-end: strip-unit($screen-end);

    $value-diff: $value-start - $value-end;
    $screen-diff: $screen-start - $screen-end;

    $scaler: math.div(strip-unit($value-diff), $screen-diff);
    $vw: $scaler * 100vw;
    @if string.index('px', math.unit($value-start)) == null {
      // Convert from rem to px scaling if the values aren't in pixels
      $vw: $vw * 16 * 0.625;
    }
    $out-base: $value-end - $scaler * $screen-end;

    // If browser support clamp, we can just use that directly
    @supports (font-size: clamp(0px, 0px, 0px)) {
      @include special-prop($prop, clamp(#{math.min($value-start, $value-end)}, #{$out-base} + #{$vw}, #{math.max($value-start, $value-end)}));
    }

    // If browser doesn't support clamp we use media queries to emulate it
    @supports not (font-size: clamp(0px, 0px, 0px)) {
      @if $screen-end > 0 {
        @include special-prop($prop, $value-end);
        // Emulate max() by forcing min value below $screen-end
        @media (min-width: #{$screen-end}px) {
          @if $out-base > 0 or $out-base < 0 {
            @include special-prop($prop, calc(#{$out-base} + #{$vw}));
          } @else {
            @include special-prop($prop, #{$vw});
          }
        }
      } @else {
        @if $value-end > 0 or $value-end < 0 {
          @include special-prop($prop, calc(#{$value-end} + #{$vw}));
        } @else {
          @include special-prop($prop, #{$vw});
        }
      }
      // Emulate min() by forcing max value past screen-max
      @media (min-width: #{$screen-start}px) {
        @include special-prop($prop, $value-start);
      }
    }
  }
}
