.cmt-form {
  font-size: 1.6rem;

  input,
  textarea {
    border: 0px solid transparent;
    box-shadow: none;
    text-shadow: none;
    border-radius: 0;
    color: #000;
  }

  input,
  textarea,
  button {
    width: 100%;
    padding: 6px 12px;
  }

  button {
    border: 0px solid transparent;
    box-shadow: none;
    text-shadow: none;
    border-radius: 0;

    color: #fff;
    background: #b00003;

    padding: 24px 32px;
    font-size: 2rem;
    text-transform: uppercase;
  }

  label {
    display: block;
    margin-bottom: 0.8rem;
  }

  .cmt-form-input {
    margin-bottom: 1.2rem;
    color: grey;
    font-size: 0.8em;
    text-transform: uppercase;
  }

  .cmt-form-number {
    display: flex;
    text-align: left;
    label {
      flex: 0 0 50%;
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }
    input {
      flex: 0 0 50%;
    }
  }
}

.no-js .cmt-form {
  display: none;
}
