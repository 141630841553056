html {
  font-size: 62.5%;
}

body,
button,
input,
select,
textarea {
  color: $color__text-main;
  font-size: 1.3rem;
  font-family: $font__main;
  line-height: $font__line-height-body;
  font-weight: 400;
}
