footer {
  padding: 4rem 0;

  background: #eae7e2;
  color: #808080;

  hr {
    width: 0;
    height: 2.4rem;
    margin: 2.0rem auto;
    border: 0;
    border-right: 2px solid #ae0002;
  }
}

.footer-title {
  color: #000;
  font-weight: 600;
  text-transform: uppercase;
}

.opening-hours-group {
  div:first-child {
    color: #000;
    font-weight: 600;
    text-transform: uppercase;
  }
  & + & {
    margin-top: 24px;
  }
}
