$breakpoint__medium: 920px;
$breakpoint__small: 560px;

// Side Menu
$sidemenu__width: 320px;
$sidemenu__transition: 250ms;
$sidemenu__breakpoint: 420px;
$breakpoint__no-js-wide: 1330px;

// Settings
$sidemenu_desktop: false;
$sidemenu_header: false;
$sidemenu_left: true;
