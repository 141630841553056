.side-panel {
  // Positioning
  position: fixed;
  z-index: 3;
  order: 1; // Place side panel last in visual order, so it appears at the bottom for no-js

  // Set panel top position & height depending on if the site header is above it or not
  @if $sidemenu_header {
    top: var(--header-height);
    height: calc(100% - var(--header-height));
  } @else {
    top: 0;
    height: 100%;
  }

  // Place the panel on the left or right depending on variable
  @if $sidemenu_left {
    left: 0;
    transform: translateX(-$sidemenu__width);
  } @else {
    right: 0;
    transform: translateX($sidemenu__width);
  }

  // Panel Styling
  width: $sidemenu__width;
  background: $color__background-side-panel;
  user-select: none;

  // Infrakultur GDPR integration
  @if $sidemenu_desktop {
    body.cookie-notification-visible & {
      padding-bottom: var(--cookie-notification-height);
      // When js is disabled & panel is at bottom at site, remove padding
      @media not all and (min-width: $breakpoint__no-js-wide) {
        .no-js & {
          padding-bottom: 0;
        }
      }
    }
  }

  // If panel is not used on desktop, make sure it's always hidden when on desktop mode
  @if not $sidemenu_desktop {
    @media (min-width: $sidemenu__breakpoint) {
      display: none;
    }
  }

  // Handle toggling of the panel
  transition: visibility $sidemenu__transition, transform $sidemenu__transition;
  visibility: hidden;
  .side-panel-toggled & {
    visibility: visible;
    -webkit-overflow-scrolling: touch !important;
    transform: translateX(0);
  }

  // no-js shows panel at all times at the bottom of the page by default
  .no-js & {
    visibility: visible;
    position: static;
    height: auto;
    width: 100%;
    transform: translateX(0);
    @if $sidemenu_desktop {
      @media (min-width: $breakpoint__no-js-wide) {
        position: fixed;
        top: 0;
        height: 100%;
        width: $sidemenu__width;
      }
    }
    > ul {
      height: auto;
    }
  }

  > ul {
    list-style: none;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;

    padding: 6.4rem;

    color: #dfdfdf;
    text-align: left;
    font-size: 1.3rem;
    font-weight: 500;
    text-transform: uppercase;

    li {
      margin: 0;
    }

    a {
      display: block;
      text-decoration: none;
      color: inherit;
      outline: none;
      &:hover,
      &:focus {
        color: #fff;
      }
    }

    // Style top level items
    > li {
      + li {
        margin-top: 2.5em;
      }
      &.current-menu-item {
        > a:before {
          content: '';
          display: inline-block;
          height: 1em;
          vertical-align: middle;
          margin-right: 0.8rem;
          margin-top: -0.16rem;
          width: 0.2rem;
          background: $color__branding-primary;
        }
      }
    }

    // Style submenus
    .sub-menu {
      color: #afafaf;
      font-size: 0.875em;
      letter-spacing: 0.05em;
      font-weight: 400;

      li {
        margin-top: 1em;
      }
    }
  }
}

// In no-js, panel is always shown past the no-js breakpoint.
// We need to account for this so that it does not overlap the site header & content
@if $sidemenu_desktop {
  @media (min-width: $breakpoint__no-js-wide) {
    .no-js {
      .page {
        width: calc(100% - #{$sidemenu__width});
      }
      .page {
        @if $sidemenu_left {
          margin-left: $sidemenu__width;
        } @else {
          margin-right: $sidemenu__width;
        }
      }
    }
  }
}
