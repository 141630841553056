// Allows you to assign a value to multiple properties separated by /
@mixin special-prop($prop, $value) {
  $index: string.index("#{$prop}", "/");
  @while $index != null {
    $current: string.slice("#{$prop}", 0, $index - 1);
    #{$current}: $value;

    $prop: string.slice("#{$prop}", $index + 1, -1);
    $index: string.index("#{$prop}", "/");
  }
  @if $prop {
    #{$prop}: $value;
  }
}
