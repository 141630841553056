$overlay_btn_size: 4.8rem;

.overlay-menu-toggle {
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: border-radius 200ms,
    background-color 200ms;
  }

  &:active,
  &:focus {
    &:before {
      border-radius: 0;
      background-color: rgba(0,0,0,0.2);
      transition: border-radius 300ms,
      background-color 300ms;
    }
  }

  position: fixed;
  z-index: 100;
  top: 16px;
  right: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: $overlay_btn_size;
  height: $overlay_btn_size;
  background-color: rgba(127, 127, 127, 0.5);

  color: #FFF !important;

  visibility: hidden;
  opacity: 0;
  transition: opacity 200ms,
  visibility 200ms;

  .side-panel-toggled & {
    visibility: visible !important;;
    opacity: 1 !important;;
  }

  .no-js & {
    display: none;
  }

  @if not $sidemenu_desktop {
    @media (min-width: $sidemenu__breakpoint) {
      display: none;
    }
  }
}
