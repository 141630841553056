html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  height: 100%;
  background: $color__background-body;
  text-align: center;
}

html,
body {
  min-height: 100%;
}

a {
  color: inherit;
}

table {
  color: inherit;
}

hr {
  width: 1rem;
  margin: 3.2rem auto;
  height: 0;
  border: 0;
  border-bottom: 2px solid #000;
}

section {
}

.section-about {
  @include responsive-size(padding-top/padding-bottom, 320px, $breakpoint__medium, 64px, $breakpoint__small);
}

.section-menu {
  @include responsive-size(padding-top/padding-bottom, 128px, $breakpoint__medium, 48px, $breakpoint__small);
}

.section-menu {
  @include responsive-size(padding-top/padding-bottom, 128px, $breakpoint__medium, 48px, $breakpoint__small);
}

.container {
  margin: 0 auto;
  max-width: 100%;
  padding: 0 16px;
}

.menu-item {
  color: #808080;

  h4 {
    color: #b00000;
  }

  p {

  }
}
.menu-price {
  margin: 0 0 2.4rem;
  color: #000000;
}

.menu-table {
  width: 100%;
  font-size: inherit;
  line-height: 0.7;
  text-transform: uppercase;
  th, td {
    padding: 0 0 4px;
  }
  th {
    font-size: inherit;
    font-weight: inherit;
    text-align: left;
    width: 0;
    padding-right: 24px;
  }
  td {
    text-align: right;
    color: #000000;

    display: flex;
    flex-direction: row;

    &:before {
      content: '';
      border: 0 solid transparent;
      border-bottom: 2px dotted #808080;
      display: block;
      flex: 1 0 auto;
      height: 0;
      align-self: flex-end;
      margin-right: 0.2rem;
    }
  }
}

.social-nav {
  display: inline-flex;
  margin: 0 -0.8rem;

  a {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    margin: 0 0.8rem;

    width: 2.6rem;
    height: 2.6rem;

    border: 2px solid currentColor;
    border-radius: 50%;

    text-decoration: none;
  }
  svg {
    display: block;
    width: 1.2rem;
    height: 1.2rem;
  }
}
