@mixin hamburger($line_height: 0.2rem, $button_height: 1.6rem, $button_width: 1.6rem, $border-radius: 0rem, $color: currentColor) {
  width: $button_width;
  position: relative;
  margin: auto;
  transition: background-color .2s ease;

  &,
  &:before,
  &:after {
    color: inherit;
    height: $line_height;
    border-radius: $border-radius;
    background-color: $color;
    backface-visibility: hidden;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    transition: transform .2s ease,
    bottom .2s ease,
    top .2s ease;
  }
  &:before {
    bottom: math.div($button_height, 2) - math.div($line_height, 2);
  }
  &:after {
    top: math.div($button_height, 2) - math.div($line_height, 2);
  }
}

@mixin hamburger-closed() {
  background-color: transparent !important;
  &:before {
    transform: rotate3d(0,0,1,45deg);
    bottom: 0;
  }
  &:after {
    transform: rotate3d(0,0,1,-45deg);
    top: 0;
  }
}
