header {
  color: #e2d1bf;

  .container {
    display: flex;
    justify-content: space-between;
  }

  padding: 16px 0;
  @media not all and (min-width: $sidemenu__breakpoint) {
    padding-bottom: 16px;
  }
}

.site-nav {
  max-width: 600px;
  margin: 1.6rem auto 0;
  padding: 0 16px;

  font-size: 1.0rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1rem;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    justify-content: space-between;
  }
  li {

  }
  a {
    display: block;
    text-decoration: none;
    padding: 0.8rem;
    padding-right: 0.7rem; // Subtract letter-spacing

    color: #fff;
  }

  @media not all and (min-width: $sidemenu__breakpoint) {
    display: none;
  }
}

.menu-toggle {
  display: flex;
  width: 2.6rem;
  height: 2.6rem;

  // If panel is not used on desktop, make sure toggler is hidden when on desktop mode
  @if not $sidemenu_desktop {
    @media (min-width: $sidemenu__breakpoint) {
      display: none;
    }
  }

  // In no-js mode, hide the toggler when the side-menu is always shown on the side
  .no-js & {
    @media (min-width: $breakpoint__no-js-wide) {
      display: none;
    }
  }
}

.menu-toggle-icon {
  @include hamburger(0.2rem, 1.2rem, 1.8rem);

  .side-panel-toggled & {
    @include hamburger-closed();
  }
}

.header-hamburger,
.header-social {
  overflow: hidden;
  width: 150px;
  @media not all and (min-width: $sidemenu__breakpoint) {
    width: 2.6rem;
  }
}

.header-hamburger {
  text-align: left;
}

.header-social {
  text-align: right;

  @media not all and (min-width: $sidemenu__breakpoint) {
    visibility: hidden;
  }
}
