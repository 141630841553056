h1, h2, h3, h4, h5, h6 {
  margin: 0;
  clear: both;
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
}

h2 {
  margin: 0 0 1.6rem;
}

h3 {
  margin: 0 0 1.2rem;
}

h4 {
  margin: 0 0 2rem;
}
