// Used to crop media sizer within a max-width/max-height boundary
.media-boundary {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

// Assign space and crop media based on the specified aspect ratio and min-width/min-height boundary
.media-sizer {
  position: relative;

  width: 100%;
  height: 0;
  padding-top: calc(100% / var(--media-ratio));

  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    // object-fit: cover; has a subpixel where it doesn't cover properly so we try to work around it
    width: calc(100% + 0.1px);
    height: calc(100% + 0.1px);
    object-fit: cover;
  }
}

.media-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-grid {
  --grid-columns: 2;
  --media-ratio: 1; // Defaults to showing images as squares

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > div {
    flex: 0 0 calc(100% / var(--grid-columns));
    width: 0;
  }
}
